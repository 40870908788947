import '../stylesheets/application.scss';
import Logo from './Logo';
import Works from './Works';

const App = () => (
  <>
    <Logo />
    <Works />
  </>
);

export default App;
