import { useEffect, useState } from 'react';
import client from '../helpers/contentful';
import id from '../helpers/youtube';

export default () => {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    client
      .getEntries({ content_type: 'work', order: '-fields.yearFilmed' })
      .then(response => {
        setWorks(response.items.map(record => {
          const { fields } = record;

          if ((!fields.isPrivateVideo || fields.isPrivateVideo) && fields.thumbnail) {
            fields.thumbnail = fields.thumbnail.fields.file.url;
          } else {
            fields.thumbnail = `http://i3.ytimg.com/vi/${id(fields.url)}/hqdefault.jpg`;
          }

          return fields;
        }));
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="works">
      {works.map(work => (
        <a className="card" href={work.url} target="_blank" rel="noreferrer" key={work.url}>
          <div
            style={{ backgroundImage: `url('${work.thumbnail}')` }}
          />
          <p>{work.title}</p>
          <small>{work.yearFilmed}</small>
        </a>
      ))}
    </div>
  );
};
