export default url => {
  const match = url.match(
    // eslint-disable-next-line no-useless-escape
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i
  );

  if (match && match[1].length === 11) return match[1];

  // TODO: think of what to return if the id is invalid
  return 'invalidId';
};
